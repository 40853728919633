import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';

import { AppBar, Typography, Box, Grid, TextField, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import AccountMenu from "./TopMenu";
import {getFormattedDate} from "./Utils"

const TodayNotes = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [notes, setNotes] = useState([{id:'', title:'', data:'', created_at:''}]);
    const [note, setNote] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        loadNotes(selectedDate);
    }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadNotes = (date) => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/notes/get`, {
            date: date,
            user_id: Cookies.get('user_id'),
        })
            .then(response => {
                setNotes(response.data);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });

        return notes;
    }

    const renderNotes = () => {
        return (
            <Box sx={{
                maxHeight: isMobile? '400px':'500px',
                overflowY: 'auto',
                '--Grid-borderWidth': '1px',
                borderTop: 'var(--Grid-borderWidth) solid',
                borderLeft: 'var(--Grid-borderWidth) solid',
                borderBottom: 'var(--Grid-borderWidth) solid',
                borderColor: 'divider',
                '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderBottom: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                },
                borderRadius: '10px'
            }}>
                {
                    notes.map((note) => (
                    <Paper key={note.id} sx={{ margin: 2, padding: 2, borderRadius: '10px' }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: 'h9.fontSize' }} >
                            {note.title.length > 40 ? `${note.title.substring(0, 40)}...` : note.title}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'light' }}>
                            {note.data.length > 50 ? `${note.data.substring(0, 50)}...` : note.data}
                        </Typography>
                        <Typography variant="caption" sx={{fontStyle: 'italic'}}>{note.created_at}</Typography>
                    </Paper>
                ))}
            </Box>
        )
    }

    const loadCalendar = () => {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                    sx={{
                        backgroundColor: '#f3f1f1'
                    }}
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={selectedDate}
                    onChange={handleDateChange}
                    maxDate={new Date().setDate(new Date().getDate())}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        )
    }

     const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handlePublish = () => {
        const postData = {
            title: title,
            data: note,
            user_id: Cookies.get('user_id')
        };

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/notes`, postData)
            .then(response => {
                setTitle('');
                setNote('');
                loadNotes(getFormattedDate());
            })
            .catch(error => {
                console.error('Error sending data: ', error);
            });
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <AccountMenu/>
            </AppBar>

            <Grid container spacing={2} sx={{ p: 3 }}>
                {/*If mobile version - calendar is on the top*/}
                {isMobile && (
                    <Grid item xs={12}>
                        <Accordion
                            square={true} sx={{borderRadius: '10px',  backgroundColor: '#f3f1f1'}}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Calendar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {loadCalendar()}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {/*Text field position doesn't depend on device*/}
                <Grid item xs={12} md={9}>
                    <TextField
                        id={'title'}
                        multiline
                        rows={1}
                        sx={{
                            marginBottom: 1,
                            ".MuiOutlinedInput-root": {
                                borderRadius: 2
                            }
                        }}
                        placeholder="Title"
                        fullWidth
                        variant="outlined"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />

                    <TextField
                        id={'note'}
                        multiline
                        rows={isMobile ? 6 : 10}
                        sx={{
                            ".MuiOutlinedInput-root": {
                                borderRadius: 2
                            }
                        }}
                        placeholder="Enter text"
                        fullWidth
                        variant="outlined"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" sx={{ mt: 2, width: isMobile ? '100%' : '10%', borderRadius: '8px' }} onClick={handlePublish}>
                            Publish
                        </Button>
                    </Box>

                </Grid>


                {/*If desktop version - calendar is on the right to text field*/}
                {!isMobile && (
                    <Grid item xs={12} md={3}>
                        <Box sx={{ width: '30%' }}>
                            {loadCalendar()}
                        </Box>

                        <Grid item xs={12}>
                            {renderNotes()}
                        </Grid>

                    </Grid>
                )}

                {isMobile && (
                    <Grid item xs={12}>
                        <Accordion square={true} sx={{
                            borderRadius: '10px',
                            backgroundColor: '#f3f1f1'
                        }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Previous notes</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {renderNotes()}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

            </Grid>
        </Box>
    );
};

export default TodayNotes;

