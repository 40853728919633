import React, { useState } from 'react';

import { Container, Paper, Tab, Tabs, Box, Typography, TextField, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios from 'axios';
import Cookies from 'js-cookie';

import "./LoginPage.css"
import myGif from './login-back-img.png'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


const LoginPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [tabValue, setTabValue] = useState(0);

    const [signUpInputs, setSignUpInputs] = useState({ firstName: "", lastName: "", email:"", password:"" });
    const [signInInputs, setSignInInputs] = useState({ email:"", password:"" });
    const [otcInputs, setOTCInputs] = useState("");

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogMessageSeverity, setDialogMessageSeverity] = useState('error');

    const [waitingOtcCode, setWaitingOtcCode] = useState(false);

    const [tabText, setTabText] = useState("SIGN IN");

    const handleSignUpDataChange = e => {
        const { name, value } = e.target;
        setSignUpInputs(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSignInDataChange = e => {
        const { name, value } = e.target;
        setSignInInputs(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

   const handleOTCChange = e => {
       const value  = e.target.value;
       setOTCInputs(prevState => ({
            ...prevState,
           value
        }));
    };

    const handleSignUp = () => {
        const postData = {
            first_name: signUpInputs.firstName,
            last_name: signUpInputs.lastName,
            email: signUpInputs.email,
            password: signUpInputs.password,
        };

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup`, postData)
            .then(response => {
                Cookies.set('user_id', response.data.user_id, { expires: 1/24 });
                setSignUpInputs({ firstName: "", lastName: "", email:"", password:"" })
                setWaitingOtcCode(true);
                setTabValue(2);
            })
            .catch(error => {
                console.error('Error sending signup request: ', error);
                setDialogMessage(error.response.data.message || 'An error occurred during signup.' + error.response.data);
                setDialogMessageSeverity("error");
                setDialogOpen(true);
            });
    }

    const handleSignIn = () => {
        const postData = {
            email: signInInputs.email,
            password: signInInputs.password,
        };

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, postData)
            .then(response => {
                const expirationDate = new Date(response.data.exp);
                Cookies.set('token', response.data.token, { expires: expirationDate });
                setSignInInputs({ email:"", password:"" })
                // setDialogMessage("Login success");
                // setDialogMessageSeverity("success");
                // setDialogOpen(true);
                console.log("redirecting to notes after login");
                window.location.href = '/notes';
            })
            .catch(error => {
                console.error('Error sending login request: ', error);
                setDialogMessage(error.response.data.message || 'An error occurred during login.' + error.response.data);
                setDialogMessageSeverity("error");
                setDialogOpen(true);
            });
    }

    const handleSubmitOTC = () => {
        const postData = {
            user_ID: Cookies.get('user_id'),
            otc: otcInputs.value,
        };

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/otc`, postData)
            .then(response => {
                setWaitingOtcCode(false);
                setDialogMessage("OTC code successfully applied! Now use your login and password to Sign In into the system!");
                setDialogMessageSeverity("success");
                setDialogOpen(true);
                setTabValue(0);
            })
            .catch(error => {
                console.error('Error applying otc request: ', error);
                setDialogMessage(error.response.data.message || 'An error occurred during applying otc.' + error.response.data);
                setDialogMessageSeverity("error");
                setDialogOpen(true);
            });
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleTabTextChange = (newValue) => {
        setTabText(newValue.target.innerText);
    };

    const handleModalWindowClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setDialogOpen(false);
    };

    const renderErrorDialog  = () => (
        <Stack spacing={2} sx={{width: '100%'}}>
            <Snackbar open={dialogOpen} autoHideDuration={5000} onClose={handleModalWindowClose}>
                <div style={{
                    margin: "0 auto",
                    width: !isMobile ? "50%" : "100%",
                    position: "fixed",
                    alignItems: "center",
                    top: 0, left: 0, right: 0,
                }}>
                    <Alert onClose={handleModalWindowClose} severity={dialogMessageSeverity}>
                        {dialogMessage}
                    </Alert>
                </div>
            </Snackbar>
        </Stack>
    );

    return (
        <div>
            {dialogOpen &&
                renderErrorDialog()
            }

            <div className="main-container">
                {!isMobile && (
                        <Container maxWidth="md" >
                            <img src={myGif} width={"100%"} height={"100%"} alt="my-gif" />
                        </Container>
                    )
                }

                <Box>
                    <Container
                            maxWidth="xs"
                            sx={{
                                margin: !isMobile? 2: 0
                            }}>

                            <Paper sx={{ marginTop: 5, display: 'flex',  flexDirection: 'column', alignItems: 'center', padding: 2, bgcolor: '#FDF5E6' }}>
                                <LockOutlinedIcon color="primary" sx={{ m: 1, bgcolor: 'background.paper', borderRadius: '50%', padding: '10px' }} />
                                <Typography component="h1" variant="h5">
                                    {tabText}
                                </Typography>
                                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" centered>
                                    <Tab label="Sign In" onClick={handleTabTextChange} />
                                    <Tab label="Sign Up" onClick={handleTabTextChange} />
                                    {waitingOtcCode && <Tab label="Code" onClick={handleTabTextChange} />}
                                </Tabs>
                                <TabPanel value={tabValue} index={0}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        value={signInInputs.email}
                                        onChange={handleSignInDataChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        value={signInInputs.password}
                                        onChange={handleSignInDataChange}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={handleSignIn}
                                    >
                                        Sign In
                                    </Button>
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                        autoComplete="fname"
                                        autoFocus
                                        value={signUpInputs.firstName}
                                        onChange={handleSignUpDataChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lname"
                                        value={signUpInputs.lastName}
                                        onChange={handleSignUpDataChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={signUpInputs.email}
                                        onChange={handleSignUpDataChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        value={signUpInputs.password}
                                        onChange={handleSignUpDataChange}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSignUp}
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Sign Up
                                    </Button>
                                </TabPanel>
                        {waitingOtcCode &&
                            (
                                <TabPanel value={tabValue} index={2}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="otc"
                                        label="Otc code"
                                        name="otc"
                                        autoFocus
                                        onChange={handleOTCChange}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 3, mb: 2 }}
                                        value={otcInputs}
                                        onClick={handleSubmitOTC}
                                    >
                                        Submit
                                    </Button>
                                </TabPanel>
                            )}
                            </Paper>
                    </Container>
                </Box>
            </div>
        </div>
    );
}

export default LoginPage;
