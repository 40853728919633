import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React  from 'react';

import LoginPage from './LoginPage';
import TodayNotes from './TodayNotes';
import "./App.css"

import Cookies from "js-cookie";

function App() {
    return (
        <div className={'common'}>
            <Router>
                <Routes>
                    <Route path="/notes" element={Cookies.get('token') ? <TodayNotes /> : <Navigate to="/login" />} />
                    <Route path="/login" element={Cookies.get('token') ? <Navigate to="/notes" /> : <LoginPage />} />

                    <Route path="/" element={<Navigate to="/notes" replace />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
